(function () {
    function r(e, n, t) {
        function o(i, f) {
            if (!n[i]) {
                if (!e[i]) {
                    var c = "function" == typeof require && require;if (!f && c) return c(i, !0);if (u) return u(i, !0);var a = new Error("Cannot find module '" + i + "'");throw a.code = "MODULE_NOT_FOUND", a;
                }var p = n[i] = { exports: {} };e[i][0].call(p.exports, function (r) {
                    var n = e[i][1][r];return o(n || r);
                }, p, p.exports, r, e, n, t);
            }return n[i].exports;
        }for (var u = "function" == typeof require && require, i = 0; i < t.length; i++) {
            o(t[i]);
        }return o;
    }return r;
})()({ 1: [function (require, module, exports) {
        function setActiveTab($parent, num) {
            var $switchTab = $parent.find('.switchTab[data-slide="' + num + '"]');
            $parent.find('.w-prices__content .nav-tabs .active').removeClass('active');
            $switchTab.parent().addClass('active');
            bcParams.activeTab = num;
        }

        var translate = bestCountryPriceTranslates;
        var links = bestCountryPriceLinks;

        var bcParams = bestCountryPricesWidgetMenuParams;
        bcParams.activeTab = typeof pricesSwiperActiveTabIndex === 'undefined' ? null : pricesSwiperActiveTabIndex;
        var isHaveSwiper = bcParams.activeTab !== null;

        if (isHaveSwiper) {
            var initSwiperParams = {
                direction: "horizontal",
                setWrapperSize: true,
                spaceBetween: 2,
                slidesPerView: 1,
                autoHeight: true,
                grabCursor: true,
                initialSlide: bcParams.activeTab,
                // preventClicks : false,
                // preventClicksPropagation : false,
                on: {
                    slideChangeTransitionStart: function slideChangeTransitionStart() {
                        var swiper = this;
                        if (swiper.activeIndex != bcParams.activeTab && typeof countriesSwiper !== 'undefined' && swiper.$wrapperEl.hasClass('related-swiper')) {
                            countriesSwiper.slideTo(swiper.activeIndex);
                        }
                        setActiveTab(swiper.$wrapperEl.closest('.w-prices'), swiper.activeIndex);
                    }
                },
                preloadImages: false,
                preloaderClass: 'lazy-preloader'
            };
            var countriesSwiper = new Swiper(".w-prices .w-prices__items .swiper-container", initSwiperParams);
            var relatedSwiper;

            $('body').on('click', '.w-prices__content .switchTab', function () {
                var num = $(this).data('slide');
                var $parent = $(this).closest('.w-prices');
                var $swiperWrapper = $parent.find('.swiper-wrapper');
                setActiveTab($parent, num);
                if ($swiperWrapper.hasClass('related-swiper') && relatedSwiper) {
                    relatedSwiper.slideTo(num);
                } else {
                    countriesSwiper.slideTo(num);
                }
            });

            if (!bcParams.departCity) {
                $(window).on('onLocation', function (e, city) {
                    var c = bcParams.dcLocation;
                    if (c[city]) {
                        var obj = $('.w-prices .nav-tabs li a[data-dc-id="' + c[city] + '"]');
                        if (!obj.hasClass('active')) {
                            obj.click();
                        }
                    }
                });
            }
        }

        $(document).ready(function () {
            var $itemsContainer = $('.w-prices__items');
            function loadImages(onlyVisibleNow) {
                var itemsSelector = '.country-item.lazy-item' + (onlyVisibleNow ? ':not(.hidden-on-preview)' : '');
                var $items = $itemsContainer.find(itemsSelector);
                $items.removeClass('lazy-item');
                $items.each(function (i, element) {
                    window.brxLazyImage.loadChildren(element);
                });
            }

            if ($itemsContainer.length) {
                if (window.utils.isAnyPartOfElementInViewport($itemsContainer)) {
                    loadImages(true);
                } else {
                    var onScroll = window.utils.throttle(function () {
                        if (window.utils.isAnyPartOfElementInViewport($itemsContainer)) {
                            loadImages(true);
                            $(window).off('scroll', onScroll);
                        }
                    }, 100);
                    $(window).scroll(onScroll);
                }
            }

            var $menuCountryItems = $('.w-prices .tours-country-menu .replace_target');
            if ($menuCountryItems.length) {
                $.each($menuCountryItems, function () {
                    var $self = $(this);
                    var data = $self.data();
                    var url = links.tours + '/' + bcParams.countries[data.countryId];
                    if (data.dc && bcParams.departCities.hasOwnProperty(data.dc)) {
                        url += '-from-' + bcParams.departCities[data.dc];
                    }
                    $self.replaceWith('<a href="' + url + '">' + $self.html() + '</a>');
                });
            }

            if (isHaveSwiper) {
                var $mainCountryItems = $('.w-prices .swiper-slide .country-item');
                if ($mainCountryItems.length) {
                    $.each($mainCountryItems, function () {
                        var t = $(this);
                        var dc = t.closest('.swiper-slide').data('dc-id');
                        if (!t.find('a').length) {
                            var href = links.tours + '/' + bcParams.countries[t.data('country-id')] + (dc ? '-from-' + bcParams.departCities[dc] : '');
                            t.html('<a href="' + href + '" class="country-link">' + t.html() + '</a>');
                        }
                    });
                }

                countriesSwiper.$wrapperEl.find('img.swiper-lazy').each(function () {
                    this.onload = function () {
                        $(this).parent().find('.' + countriesSwiper.preloaderClass).remove();
                    };
                    this.src = this.getAttribute('data-src');
                });

                var $btnMoreCountriesTop = $('.w-prices-more-count');
                var $curSlideCountryItems = countriesSwiper.$wrapperEl.find('.swiper-slide[data-dc-id="' + $btnMoreCountriesTop.data('dc-id') + '"] .country-item');
                var cnt = $curSlideCountryItems.length;
                $btnMoreCountriesTop.find('span.mlabel').html(translate.more + ' ' + getnoun(cnt - 1, translate.pluralCountries[0], translate.pluralCountries[1], translate.pluralCountries[2]));

                $('.w_prices_more_show').on('click', function () {
                    loadImages(false);
                    var $clone = countriesSwiper.$wrapperEl.closest('.w-prices'); //.clone();
                    initSwiperParams.initialSlide = bcParams.activeTab;
                    open_popup({
                        content: $clone[0].outerHTML,
                        title: translate.selectCountry,
                        onAfterShow: function onAfterShow() {
                            $('.mpopup .w-prices').removeClass('preview').addClass('top-indent');
                            relatedSwiper = new Swiper('.mpopup .w-prices .w-prices__items .swiper-container', initSwiperParams);
                            relatedSwiper.$wrapperEl.addClass('related-swiper');
                        }
                    });
                });
            }
        });
    }, {}] }, {}, [1]);